/////////////////////////////////////////
// Material Angular specific additions //
/////////////////////////////////////////

.mat-date-xs {
  width: 11.5rem;
  margin-right: 1rem;
}

.mat-column-actions,
.mat-header-cell.mat-column-actions {
  padding-right: 1.5rem !important;
}

.mat-column-actions {
  padding-right: .75rem !important;
  min-width: 6.25rem;
  text-align: right;
}

.mat-header-cell.mat-column-actions {
  text-align: center !important;
}

.mat-card-content.card-infos,
.card-infos {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
}

.mat-dialog-container {
  .mat-dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin: 0;
      padding: 0 5px 0 0;
      word-wrap: anywhere;
      line-height: 100%;
    }
  }

  .buttons-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 1rem;

    button {
      margin-bottom: 0;
    }
  }
}

.mat-button-disabled .mat-icon {
  color: var(--total-clr-ui-gray-600);
}
