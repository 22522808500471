@import 'helpers';
@import 'reset';
@import 'colors';
@import 'fonts';
@import '~sass-mq/_mq';
@import '~@angular/material/theming';
@import 'material-variables';
@import 'material-override';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

@import 'variables';

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($app-light-theme);

.ff-dark-theme {
  @include angular-material-theme($app-dark-theme);
}

a,
a:not([href]),
a:not([href]):not([tabindex]) {
  color: map-get($app-primary, default);
  cursor: pointer;
  font-family: var(--total-font-roboto-regular);
  text-decoration: none;

  &:hover {
    color: map-get($app-primary, default);
    text-decoration: underline;
  }

  &:visited {
    text-decoration: none;
  }
}

body {
  background-color: var(--total-clr-ui-gray-400);
}

/* Titles */
h1 {
  font-family: var(--total-font-nunito);
  font-weight: var(--total-font-weight-nunito);
  font-size: 2.5rem;
  line-height: 2.75rem;
  margin-bottom: 0;

  padding: 1.5rem 0;
}

h2 {
  font-size: 1.875rem;
  font-family: var(--total-font-nunito);
  font-weight: var(--total-font-weight-nunito);
  padding: 1.25rem 0;
}

h3 {
  font-size: 1.625rem;
  font-family: var(--total-font-roboto);
  font-weight: var(--total-font-weight-roboto);
  padding: 1rem 0;
}

h4 {
  font-size: 1.125rem;
  font-family: var(--total-font-roboto-bold);
  padding: 1rem 0;
}

h1,
h2,
h3,
h4 {
  color: var(--total-clr-typo-gray);
}

small,
div,
p,
b,
input,
select,
option,
textarea,
label {
  font-family: var(--total-font-roboto-regular);
}

small,
span,
p,
b,
input,
select,
option,
textarea,
label {
  color: var(--total-clr-typo-gray);
}

small {
  font-size: .875rem;
}

input {
  caret-color: var(--total-clr-typo-gray) !important;
}

// Total Energies

.te-block {
  padding: 1rem;
  border-radius: var(--total-radius-block);
}
