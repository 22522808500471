@import './app/scss/variables';
@import 'assets/theme/theme';
@import 'assets/styles/material-addition.scss';

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

*.primary {
  color: var(--total-clr-typo-red);
}

body {
  font-size: 16px;
  color: var(--total-clr-typo-gray);
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}

.main-content {
  width: 100%;
  padding: 1rem;
  margin-bottom: 3rem;
}

.title-with-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-actions {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
}


.station-status-bullet {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #FFF;

  &.opened {
    background-color: var(--total-clr-success);
  }

  &.closed {
    background-color: var(--total-clr-ui-gray-800);
  }

  &.under_construction {
    background-color: var(--total-clr-warning);
  }
  &.permanently_closed {
    background-color: #000;
  }
}
