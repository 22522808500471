button.mat-raised-button,
button.mat-flat-button,
button.mat-stroked-button {
  box-shadow: none !important;
  border-radius: var(--total-radius-btn);
  border: 2px solid var(--total-clr-typo-red);
  color: var(--total-clr-typo-red);
  padding: 0 1rem;
  text-align: center;
  transition: all .2s ease-in-out;

  background-color: #ffffff;

  .mat-icon {
    margin-right: .25rem;
    color: var(--total-clr-typo-red);
  }

  .mat-button-wrapper {
    color: var(--total-clr-typo-red);
    font-family: var(--total-font-roboto-medium);
    font-weight: var(--total-font-weight-roboto-medium);
    font-size: .875rem;
    line-height: 1rem;
  }

  &:hover,
  &:active {
    &:not(:disabled) {
      background-color: var(--total-clr-typo-red);
      color: #ffffff;

      .mat-icon,
      .mat-button-wrapper {
        color: #ffffff;
      }
    }
  }

  &:disabled {
    opacity: .75;
    background-color: #ffffff !important;
    border-color: var(--total-clr-ui-gray-600);
    color: var(--total-clr-ui-gray-600);

    .mat-icon,
    .mat-button-wrapper {
      color: var(--total-clr-ui-gray-600);
    }
  }

  &.mat-primary {
    background-color: var(--total-clr-typo-red);

    .mat-icon {
      color: #ffffff;
    }

    .mat-button-wrapper {
      color: #ffffff;
    }

    &:hover,
    &:active {
      &:not(:disabled) {
        background-color: #ffffff;
        color: var(--total-clr-typo-red);

        .mat-icon,
        .mat-button-wrapper {
          color: var(--total-clr-typo-red);
        }
      }
    }

    &:disabled {
      opacity: .75;
      background-color: var(--total-clr-ui-gray-600) !important;
      border-color: var(--total-clr-ui-gray-600);
      color: #ffffff;

      .mat-icon,
      .mat-button-wrapper {
        color: #ffffff;
      }
    }
  }
}

.mat-mini-fab, .mat-fab {
  box-shadow: none !important;
}

button.mat-mini-fab {
  border: 2px solid var(--total-clr-typo-red);
  color: var(--total-clr-typo-red);
  text-align: center;
  transition: all .2s ease-in-out;

  background-color: #ffffff;

  .mat-button-wrapper {
    padding: 0;
  }

  .mat-icon {
    color: var(--total-clr-typo-red);
  }

  &:hover:not(:disabled) {
    background-color: var(--total-clr-typo-red);
    color: #ffffff;

    .mat-icon,
    .mat-button-wrapper {
      color: #ffffff;
    }
  }

  &.mat-primary {
    background-color: var(--total-clr-typo-red);

    .mat-icon {
      color: #ffffff;
    }

    &:hover {
      background-color: #ffffff;
      color: var(--total-clr-typo-red);

      .mat-icon {
        color: var(--total-clr-typo-red);
      }
    }
  }
}

.mat-expansion-panel {
  box-shadow: none !important;
  background: transparent !important;
  color: var(--total-clr-typo-gray) !important;
  border-bottom: 1px solid var(--total-clr-ui-gray-600);
  border-radius: 0 !important;
}

.mat-snack-bar-container * {
  color: #ffffff;
}

.mat-menu-item {
  color: var(--total-clr-typo-gray) !important;
}

a.mat-menu-item:hover {
  text-decoration: none;
}

.mat-tab-label-active {
  .mat-tab-label-content div {
    color: var(--total-clr-typo-red);
  }
}

.mat-tab-label-content div {
  font-size: 1rem;
  font-family: var(--total-font-roboto-medium);
}

.mat-list-base .mat-list-item,
.mat-list-base .mat-list-option,
.mat-option,
.mat-option-text,
.mat-dialog-container {
  color: var(--total-clr-typo-gray);
}

.mat-dialog-container {
  padding: var(--total-radius-block);
}

mat-card.mat-card {
  box-shadow: none !important;
  color: var(--total-clr-typo-gray);

  padding: 1rem;
  border-radius: var(--total-radius-block);
  background-color: #ffffff;

  &.blue-light {
    background-color: var(--total-clr-solid-blue-light);
  }

  .mat-card-content {
    font-size: 1rem;
  }

  .mat-card-actions:last-child {
    margin-bottom: 0;
  }
}

.mat-dialog-container {
  border-radius: var(--total-radius-block) !important;
}

.mat-cell,
.mat-footer-cell {
  color: inherit !important;
}

.fs-dialog-backdrop,
.cdk-overlay-dark-backdrop {
  background-color: var(--total-clr-backdrop) !important;
}


.mat-form-field {
  width: 100%;
}

.mat-form-field-flex {
  border-bottom: 1px solid var(--total-clr-ui-gray-600);
}

.mat-select {
  .mat-select-arrow-wrapper {

    .mat-select-arrow {
      color: var(--total-clr-typo-red) !important;
    }
  }
}

.mat-hint {
  color: var(--total-clr-ui-gray-800) !important;
}

.mat-error {
  color: var(--total-clr-error) !important;
}

.mat-hint,
.mat-error {
  font-size: .75rem;
}

.mat-select-panel mat-option.mat-option {
  height: unset;
}

.mat-option.mat-option-disabled,
.mat-option.mat-option-disabled span {
  color: var(--total-clr-ui-gray-600) !important;
}

.mat-option-text.mat-option-text {
  white-space: normal;
  line-height: 1.25rem;
  padding: .75rem 0;
}


.mat-header-cell,
.mat-sort-header-content,
.mat-header-content {
  font-family: var(--total-font-roboto-bold);
  font-size: .875rem !important;
  text-transform: uppercase;
  background-color: var(--total-clr-ui-gray-600) !important;
  color: var(--total-clr-typo-gray) !important;
}

th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  padding: 0 .25rem !important;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: 1rem !important;
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding-right: .75rem !important;
}

.mat-paginator {
  .mat-paginator-container {
    justify-content: space-between;
  }

  .mat-form-field {
    .mat-form-field-wrapper {
      margin-top: .75rem;

      .mat-form-field-flex {
        padding-top: .25rem;

        .mat-form-field-infix {
          border: 0 !important;
          padding: .25rem 0 !important;
        }

        .mat-select {
          .mat-select-arrow-wrapper {
            transform: translateY(-10%);
          }
        }
      }
    }
  }
}

.disabled-input-datepicker {
  .mat-form-field-flex,
  .mat-datepicker-input {
    cursor: pointer;
  }
}



//////////////////////////////////////
// Material Angular ::ng-deep fixes //
//////////////////////////////////////

::ng-deep {
  .mat-checkbox-label {
    font-size: var(--total-fs-caption) !important;
    line-height: var(--total-lh-caption);
    font-family: var(--total-font-caption);
  }
}


.mat-form-field-appearance-outline {
  .mat-form-field-label {
    color: var(--total-clr-typo-gray) !important;
  }

  .mat-form-field-outline {
    border-radius: 10px !important;
    color: var(--total-clr-ui-gray-800) !important;
  }

  .mat-form-field-outline-end {
    border-radius: 0 10px 10px 0 !important;
  }

  .mat-form-field-outline-start {
    border-radius: 10px 0 0 10px !important;
    width: 10.5px !important;
  }

  .mat-form-field-flex {
    border-bottom: 1px solid transparent;
  }

  .mat-form-field-outline-thick {
    color: var(--total-clr-ui-gray-800) !important;
  }

  &.mat-form-field-can-float.mat-form-field-should-float {
    .mat-form-field-label {
      color: var(--total-clr-ui-gray-800) !important;
    }
  }

  &.mat-warn,
  &.mat-form-field-invalid {

    .mat-form-field-label,
    .mat-form-field-outline-thick {
      color: var(--total-clr-typo-red) !important;
    }
  }

  &.mat-form-field-disabled {

    .mat-form-field-label,
    .mat-form-field-outline {
      color: var(--total-clr-ui-gray-600) !important;
    }
  }
}

.mat-calendar {
  * {
    font-family: var(--total-font-roboto-regular) !important;
  }

  .mat-calendar-body-cell {
    .mat-calendar-body-today {
      background-color: #ffffff !important;
      color: var(--total-clr-typo-red) !important;
    }

    &:hover .mat-calendar-body-cell-content,
    .mat-calendar-body-selected {
      background-color: var(--total-clr-typo-red) !important;
      color: #ffffff !important;
    }
  }
}
